var sabanci = sabanci || {};

(function (app) {
    app.classes = {
        current: 'current',
        hidden: 'is-hidden',
        transition: 'has-transition'
    };

    app.namespace = 'sabanci';
    app.events = {
        click: 'click.' + app.namespace
    };
    app.$objs = {};
    app.modules = [];

    app.windowSize = function () {
        return $(window).width();
    };

    app.registerInit = function (module) {
        app.modules.push(module);
    };

    app.plugged = [
        {func: 'collapsible', target: '.collapsible', params: {closeSiblings: true}},
        {func: 'tabview', target: '.tab-view'}
    ];

    app.initModules = function () {
        if (app.modules.length > 0) {
            app.modules.forEach(function (module) {
                if (typeof app[module] !== 'undefined' && !app[module].isInitialized()) {
                    app[module].init();
                }
            });
        }
    };

    app.bindPlugins = function () {
        $(app.plugged).each(function () {
            var e = this,
                p;

            if (typeof $.fn[e.func] !== 'function') {
                return;
            }

            if (typeof e.params !== 'undefined') {
                p = e.params;
            }

            $(e.target)[e.func](p);
        });
    };

    app.init = function () {
        app.$objs.document = $(document);
        app.$objs.body = $('body');

        app.initModules();
        app.bindPlugins();

        $(function(){

            $('[data-fancybox="gallery"]').fancybox({
                // Options will go here
            });

            window.swiper = new Swiper ('.swiper-container', {
                pagination: '.banner-pagination',
                paginationClickable: true,
                autoplay: 3000,
                nextButton: '.swiper-button-next',
                prevButton: '.swiper-button-prev',
                onSlideChangeEnd: function(e) {
                    if(5 >= e.activeIndex) {
                        $('.banner-number').html(e.activeIndex + 1)
                    }
                },
            });

            var collection = new Swiper('.swiper-collection', {
                slidesPerView: 4,
                pagination: '.swiper-collection .swiper-pagination',
                paginationClickable: true,
                spaceBetween: 30,
                autoplay: 3000,
                nextButton: '.swiper-collection .swiper-button-next',
                prevButton: '.swiper-collection .swiper-button-prev',
                breakpoints: {
                    1320: {
                        slidesPerView: 3
                    },
                    1000: {
                        slidesPerView: 2
                    },
                    600: {
                        slidesPerView: 1
                    }
                }
            });
        });



        $('.mobile-menus').on('click', function () {
            $('.sidebar').toggleClass('open');
        });

        $('.menu a').click(function() {
            var keyword = $(this).attr('link');
            var scrollTo = $('#' + keyword);
            $('html, body').animate({
                scrollTop: scrollTo.offset().top
            }, 'slow');
            $('.sidebar').removeClass('open');
        });

        $('.whatsapp-btn, .chat-close').on('click', function () {
            $('.chat-content').toggleClass('open');
        });

    };

    app.initModules = function () {
        if (app.modules.length > 0) {
            app.modules.forEach(function (module) {
                if (typeof app[module] !== 'undefined' && !app[module].isInitialized()) {
                    app[module].init();
                }
            });
        }
    };

})(sabanci);

$(function () {
    sabanci.init();
});
